<template>
  <div
    role="dialog"
    ref="trap-container"
    tabindex="-1"
    :class="['side-pannel', { showing: show }]"
    :aira-labelledby="`${uid}-header`"
    :hidden="!show"
    :id="`${uid}-container`"
  >
    <slot></slot>
  </div>
</template>

<script>
import KeyboardTrapMixin from "@/utils/mixins/keyboardTrapMixin.js"

export default {
  name: "SidePannel",
  mixins: [KeyboardTrapMixin],
  props: {
    uid: {
      default: () => "modal",
      type: String,
      required: true
    },
    show: {
      default: () => false,
      type: Boolean,
      required: true
    },
    // toggle to update keydown event listeners
    // useful for when modal re-renders or view updates
    resetListener: {
      default: () => false,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  },
  watch: {
    resetListener: function() {
      this.resetKeydownListener()
    }
  }
}
</script>
