<template>
  <SidePannel
    uid="copying-details-pannel"
    :show="isCopyingDetails"
    :reset-listener="resetListener"
    @close="onClickCloseCopyDetails"
  >
    <Spinner
      complete-message="questions successfully saved"
      message="saving questions"
      :is-loading="isLoading"
    />
    <h2><VisibleText>Copy Details</VisibleText></h2>
    <p>
      <VisibleText>Choose questions you want to copy details to.</VisibleText>
    </p>

    <div
      class="dataset-benchmarks-selector__user-q"
      v-if="Object.keys(clientQuestion).length > 0"
    >
      <VisibleText class="dataset-benchmarks-selector__subtitle">
        Your Question
      </VisibleText>
      <p>
        {{ clientQuestion.question_title }} -
        {{ clientQuestion.question_text }} <br />
      </p>
      <CopyDetailsPannelValuesList
        style="background: #fff"
        :question="clientQuestion"
      />
    </div>

    <VisibleText class="dataset-benchmarks-selector__subtitle">
      Select Questions
    </VisibleText>

    <div class="dataset-benchmarks-selector__search forms__search">
      <label class="forms__search-label" for="question-search">
        <span class="visually-hidden" :data-parent="$options.name">
          {{ translate("Search global questions", $options.name) }}
        </span>
        <SvgIconDecorative icon="search" />
      </label>
      <input
        type="text"
        class="forms__search-input"
        id="question-search"
        name="search__questions"
        :placeholder="visiblePlaceholder"
        v-model="questionSearch"
      />
    </div>

    <form class="dataset-benchmarks-selector__form" @submit.prevent>
      <fieldset
        class="form-fieldset-group dataset-benchmarks-selector__fieldset light-scroll-bar"
        id="copy-details-questions-group"
      >
        <legend class="sr-only">
          {{ translate("select question", $options.name) }}
        </legend>
        <div
          class="dataset-benchmarks-selector__form-checkbox"
          v-for="question in filteredQuestions"
          :key="`select-${question._id.$oid}`"
        >
          <label class="form-checkbox-label" :for="`gq-${question._id.$oid}`">
            <input
              class="form-checkbox-input"
              type="checkbox"
              :disabled="
                question.approved_data_type !==
                  clientQuestion.approved_data_type ||
                  question._id.$oid === clientQuestionId
              "
              :id="`gq-${question._id.$oid}`"
              :name="question._id.$oid"
              :value="question._id.$oid"
              v-model="selectedQuestions"
            />
            <span class="question-title">
              {{ question.question_title }}
            </span>
            <span class="question-text">
              {{ question.question_text }}
            </span>
          </label>
          <CopyDetailsPannelValuesList :question="question" />
        </div>
      </fieldset>
      <button
        type="submit"
        class="side-pannel__primary-btn"
        :disabled="!$pigeonline.permissions.hasPermission('update-dataset')"
        @click="onClickCopyDetails"
      >
        <VisibleText>Copy Details</VisibleText>
      </button>
      <button
        type="button"
        class="side-pannel__close-btn"
        @click="onClickCloseCopyDetails"
      >
        <VisibleText>Close</VisibleText>
      </button>
      <MessageInline :message="message.text" :message-type="message.type" />
    </form>
  </SidePannel>
</template>

<script>
// Components
import CopyDetailsPannelValuesList from "./CopyDetailsPannelValuesList.vue"
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import SidePannel from "@/components/UI/SidePannel.vue"
import Spinner from "@/components/UI/Spinner.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "DatasetTableOptionsCopyDetailsPannel",
  components: {
    CopyDetailsPannelValuesList,
    MessageInline,
    SidePannel,
    Spinner,
    SvgIconDecorative
  },
  props: {
    clientQuestionId: {
      default: () => "",
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: {
        type: "",
        text: ""
      },
      valueToLabel: new Map(),
      isLoading: false,
      questionSearch: null,
      selectedQuestions: [],
      resetListener: false
    }
  },
  computed: {
    clientQuestion() {
      return (
        this.$store.getters["datasetDetails/getClientQuestions"].find(
          question => question._id.$oid === this.clientQuestionId
        ) || {}
      )
    },
    filteredQuestions() {
      const keys = ["question_title", "question_text"]
      return this.$options.filters.searchInObjectValues(
        this.$store.getters["datasetDetails/getClientQuestions"],
        keys,
        this.questionSearch
      )
    },
    isCopyingDetails() {
      return this.$store.getters["datasetWizard/getCopyingDetails"]
        .isCopyingDetails
    },
    visiblePlaceholder() {
      return this.checkTranslationCache(
        "search questions",
        "search__questions",
        this.$store.getters.getLanguage
      )
    }
  },
  methods: {
    onClickCloseCopyDetails() {
      this.resetMessage()
      this.$store.dispatch("datasetWizard/setCopyingDetails", {
        isCopyingDetails: false,
        methodName: "copyValAndLabel" //default value
      })
    },
    async onClickCopyDetails() {
      this.resetMessage()
      await this[
        this.$store.getters["datasetWizard/getCopyingDetails"].methodName
      ]()

      this.selectedQuestions = []
    },
    resetMessage() {
      this.message = {
        text: "",
        type: ""
      }
    },
    async copyValAndLabel() {
      this.isLoading = true
      await Promise.all(
        this.selectedQuestions.map(async questionId => {
          const clientQuestion = this.$store.getters[
            "datasetDetails/getClientQuestions"
          ].find(question => question._id.$oid === questionId)
          const uniqueValues = clientQuestion.unique_values.reduce(
            (uniquevals, value) => {
              if (this.valueToLabel.has(value.value)) {
                uniquevals.push(this.valueToLabel.get(value.value))
              } else uniquevals.push(value)
              return uniquevals
            },
            []
          )
          await this.$services.DATASETS_SERVICE.updateClientQuestion(
            clientQuestion._id.$oid,
            { unique_values: uniqueValues }
          ).catch(e =>
            console.error(
              `DatasetTableOptionsCopyDetailsPannel:copyLabels: ${e}`
            )
          )
        })
      )
        .then(
          () => (this.message = { text: "Labels copied.", type: "success" })
        )
        .catch(e => {
          console.error(`DatasetTableOptionsCopyDetailsPannel:copyLabels: ${e}`)
          this.message = {
            text: "There was a problem saving the details. Please try again.",
            type: "error"
          }
        })
      this.$emit("update-questions")
      this.isLoading = false
    }
  },
  watch: {
    clientQuestionId: {
      immediate: true,
      handler(id) {
        if (id && Object.keys(this.clientQuestion).length > 0) {
          this.clientQuestion.unique_values.forEach(value => {
            this.valueToLabel.set(value.value, value)
          })
          this.resetListener = !this.resetListener
        }
      }
    }
  }
}
</script>
