<template functional>
  <div
    :class="['dataset-benchmarks-selector__values', data.staticClass]"
    :style="data.staticStyle"
  >
    <span class="sub-title">
      {{ $options.methods.translate("values", $options.name) }}
    </span>
    <ul class="values-ul">
      <li
        v-for="(value, index) in props.question.unique_values.slice(0, 8)"
        :key="`copy-values-${props.question._id.$oid}-${index}`"
        :class="[
          'values-li',
          {
            'values-li--full-width':
              props.question.approved_data_type === 'OPEN_ENDED'
          }
        ]"
      >
        <span
          class="dataset-table__values-li__item"
          v-if="$options.isCatOrScale(props.question.approved_data_type)"
        >
          <span class="value">
            {{ value.modified_value || value.value }}
          </span>
          <span class="label">
            {{
              value.label ||
                $options.methods.translate("no label", $options.name)
            }}
          </span>
        </span>

        <span v-else>
          <span class="value">
            {{ value.value }}
          </span>
        </span>
      </li>
    </ul>
    <span v-if="props.question.unique_values.length > 8"> ... </span>
  </div>
</template>

<script>
import { SCALE_TYPE_TO_READABLE } from "@/utils/consts/constsDataTypes.js"
const isCatOrScale = dataType => {
  return ["CAT", ...Object.keys(SCALE_TYPE_TO_READABLE)].includes(dataType)
}
export default {
  props: {
    question: {
      type: Object
    }
  },
  isCatOrScale
}
</script>
